.maintenance-container {
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1c40f;
}
.maintenance-container img {
  width: 40%;
}

@media screen and (max-width: 992px) {
  .maintenance-container img {
    width: 100%;
  }
}
