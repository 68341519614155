.dgf-container {
  padding: 50px 100px;
  background-color: var(--s);
  color: #fff;
  text-align: center;
}
.dgf-container h5 {
  color: #fff;
  font-size: small;
}
.dgf-c {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.dgf {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--p);
  background-color: var(--t);
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}
.dgf .icon {
  color: var(--s);
  font-size: 70px;
  margin-bottom: 10px;
}
.dgf h4 {
  font-weight: 500;
  color: #000;
  margin: 0;
}
.dgf p {
  color: #333;
}

@media screen and (max-width: 786px) {
  .dgf-container {
    padding: 50px 20px;
    background-color: var(--p);
    color: #fff;
    text-align: center;
  }
  .dgf {
    width: 45%;
  }
  .dgf h4 {
    font-size: 14px;
    font-weight: 600;
  }
  .dgf p {
    font-size: 12px;
  }
}
