.hero-container {
  padding: 50px 100px;
  position: relative;
}
.hero-container img {
  scale: 1;
  transition: 0.3s all ease-out;
}
.hero-container img:hover {
  scale: 1.02;
}
.image-slider {
  overflow-x: scroll;
  border: 1px solid red;
  padding: 0;
}
.slide {
  padding: 0;
}
.hero-slider-btns .icon {
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  border: 2px solid var(--s);
  color: var(--s);
  padding: 5px;
  cursor: pointer;
}
.hero-slider-btns .icon:hover {
  background-color: var(--s);
  color: #000;
}

.slick-arrow {
  display: none !important;
}
.hero-slider-btns .arrow-left {
  left: 3%;
}
.hero-slider-btns .arrow-right {
  right: 3%;
}
@media screen and (max-width: 992px) {
  .hero-container {
    padding: 0;
  }
}
