.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.bal {
  width: 100%;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
}
.wallet-dash-card {
  width: 30%;
  padding: 20px;
  background-color: var(--p);
  color: #fff;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    background-color: var(--t);
    color: #000;
  }
}
.wallet-dash-card.active {
  background-color: var(--t);
  border-radius: 0px;
  color: #000;
}
.pay-online {
  background-color: var(--t);
  color: #000;
}
.add-money {
  display: flex;
  width: 100%;
  gap: 50px;
}
.add-money input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}
.add-money input::placeholder {
  color: #fff;
}
.bar-img {
  width: 50%;
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}
.txn-btns button {
  background-color: var(--p);
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  outline: transparent;
  border: transparent;
}
.txn-btns button.active {
  background-color: var(--s);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .add-money {
    padding: 10px;
    flex-wrap: wrap;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
