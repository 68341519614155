.product-info-container {
  padding: 50px 120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgb(48, 108, 108) 20%,
    rgba(19, 27, 50, 1) 100%
  );
}
.pic {
  width: 40%;
}
.product-info-img {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* background-color: var(--s); */
}
.product-info-img .game-name {
  display: flex;
  align-items: center;
  gap: 20px;
}
.product-info-img h4 {
  font-weight: 700;
  color: #fff;
}
.product-info-img span {
  color: var(--t);
}
.product-info-img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid #fff;
}
.product-info-img p {
  color: #ddd;
}
/* ============ RIGHT  */
.playername {
  background: #fff;
  padding: 2px 5px;
  border-radius: 100px;
  font-size: 14px;
  display: inline;
}
.bg-fields {
  padding: 30px;
  border-radius: 0px;
  margin-bottom: 20px;
  background-color: var(--p);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.bg-fields .player-tag {
  background-color: transparent;
  border: 1px solid var(--t);
  width: 100%;
  color: #fff;
}
.player-tag option {
  color: #000;
}
.bg-fields h5 {
  color: var(--t);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bg-fields p {
  white-space: pre-wrap;
}
.bg-fields .icon {
  color: var(--t);
  font-size: 30px;
  margin-right: 5px;
}
.bg-fields .total-value {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.bg-fields .buy-btn-container {
  margin-top: 20px;
}
.bg-fields .buy-now {
  padding: 12px 20px;
  width: 100% !important;
  margin-top: 10px;
  background-color: var(--t);
  color: #000;
  font-weight: 600;
}
.product-info-content {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
}
.player-tag {
  padding: 10px;
  width: 50%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  background-color: #ffca00;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
}
.safe-checkout-img {
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}
.p-amount {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 20px;
  row-gap: 10px;
}
.amount {
  width: 30%;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  transition: 0.5s all ease;
  &:hover {
    background-color: var(--t);
    color: #000;
  }
}
.amount.active {
  background-color: var(--t);
  color: #000;
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  background-color: #ffca00;
  padding: 10px 20px;
  margin-left: 5px;
}

.product-desc {
  padding: 50px 200px;
}
.product-desc p {
  white-space: pre-wrap;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}

.safe-checkout {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 0 0 20px 20px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  margin-top: 30px;
  span {
    background-color: var(--p);
    color: #000;
    padding: 5px;
  }
}
.safe-checkout-img {
  width: 100%;
}

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
.payment-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.payment {
  width: 30%;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
.payment .icon {
  color: #000;
  margin: 0;
}
.payment img {
  width: 70px;
  mix-blend-mode: multiply;
}
.payment.active {
  background-color: var(--t);
}

/* LOADING  */
/* LOADING  */
/* LOADING  */
.loading-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddd;
  gap: 10px;
  background: linear-gradient(
    180deg,
    rgb(48, 108, 108) 20%,
    rgba(19, 27, 50, 1) 100%
  );
}
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
.order-succcess-container {
  padding: 100px 200px;
  min-height: 100vh;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(74, 39, 69, 1) 5%,
    rgba(33, 32, 63, 1) 35%,
    rgba(19, 27, 50, 1) 50%,
    rgba(19, 27, 50, 1) 100%
  );
}
.order-recpt {
  width: 50%;
  background: rgba(255, 255, 255, 0.43);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.order-recpt .order-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.coupon-box {
  display: flex;
  gap: 0;
  margin-top: 15px;
}
.coupon-box button {
  padding: 10px;
  margin: 0;
  background-color: var(--t);
  color: #000;
  outline: transparent;
  border: transparent;
}
.coupon-tag {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 15px;
}
.coupon-tag p {
  background-color: greenyellow;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.coupon-tag .icon {
  font-size: 18px;
  color: #000;
}
.remove-coupon {
  background-color: red;
  outline: transparent;
  border: transparent;
  padding: 4px 10px;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .pic {
    width: 100%;
  }
  .product-info-img-slider {
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img-slider img {
    width: 20%;
    margin-right: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
  .product-desc {
    padding: 50px 20px;
  }
  .p-amount {
    width: 100%;
  }
  .product-info-container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 786px) {
  .loading-container {
    min-height: 60vh;
  }
  .order-succcess-container {
    padding: 50px 20px;
    min-height: 60vh;
  }
  .order-recpt {
    width: 100%;
  }
  .amount {
    width: 28%;
    font-size: 14px;
    img {
      width: 20px;
    }
  }
  .payment {
    width: 45%;
  }
  .payment img {
    width: 30px;
  }
}
@media screen and (max-width: 450px) {
  .product-info-img .game-name {
    gap: 0px;
    flex-wrap: wrap;
  }
  .product-info-img .game-name img {
    width: 100%;
    height: 200px;
  }
  .amount {
    width: 28%;
    font-size: 14px;
    img {
      width: 20px;
    }
  }
  .payment {
    width: 45%;
    font-size: 12px;
    padding: 10px;
  }
}
@media screen and (max-width: 360px) {
  .amount {
    width: 45%;
    font-size: 14px;
    img {
      width: 20px;
    }
  }
  .payment {
    width: 100%;
    font-size: 10px;
    padding: 10px;
  }
}

/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */
.spinner {
  background-image: linear-gradient(rgb(186, 66, 255) 35%, rgb(0, 225, 255));
  width: 100px;
  height: 100px;
  animation: spinning82341 1.7s linear infinite;
  text-align: center;
  border-radius: 50px;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px rgb(186, 66, 255),
    0px 5px 20px 0px rgb(0, 225, 255);
}

.spinner1 {
  background-color: rgb(36, 36, 36);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  filter: blur(10px);
}

@keyframes spinning82341 {
  to {
    transform: rotate(360deg);
  }
}
